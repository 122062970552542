import { defaultStyles } from '@/utils/defaultStyles';

const dots = [
  {
    title: 'Najwyższy standard usług',
    desc: 'Każdy magazyn utrzymany w idealnym stanie, zapewnia ochronę przed zalaniem i uszkodzeniami, by Twoje rzeczy były zawsze bezpieczne.',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_81_114)">
          <mask
            id="mask0_81_114"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="38"
            height="38"
          >
            <path d="M0 3.8147e-06H38V38H0V3.8147e-06Z" fill="white" />
          </mask>
          <g mask="url(#mask0_81_114)">
            <path
              d="M30.1328 19C30.1328 25.1485 25.1485 30.1328 19 30.1328C12.8515 30.1328 7.86719 25.1485 7.86719 19C7.86719 12.8515 12.8515 7.86719 19 7.86719C25.1485 7.86719 30.1328 12.8515 30.1328 19Z"
              stroke="#54ABF9"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.5664 15.6602L17.8867 22.3398L13.4336 17.8867"
              stroke="#54ABF9"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5561 1.64488C18.3883 0.936088 19.6117 0.936088 20.4438 1.64488L23.3371 4.19837L28.2333 3.81949C29.3231 3.73518 30.3129 4.45428 30.5694 5.5168L31.5511 9.5822L35.8289 12.1429C36.7602 12.7153 37.1382 13.8789 36.7213 14.8892L35.0312 18.9844L36.7213 23.0795C37.1382 24.09 36.7602 25.2536 35.8289 25.8259L31.5511 28.3866L30.5694 32.452C30.3129 33.5145 29.3231 34.2337 28.2333 34.1493L23.385 33.7741L20.4438 36.3551C19.6117 37.0639 18.3883 37.0639 17.5561 36.3551L14.615 33.7741L9.76674 34.1493C8.67691 34.2337 7.68706 33.5145 7.43056 32.452L6.44501 28.3705L2.17431 25.8259C1.24301 25.2536 0.864945 24.09 1.28198 23.0795L2.97194 18.9844L1.28198 14.8892C0.864945 13.8789 1.24301 12.7153 2.17431 12.1429L6.07896 9.82319L7.43056 5.5168C7.68706 4.45428 8.67691 3.73518 9.76674 3.81949L14.663 4.19837L17.5561 1.64488Z"
              stroke="#54ABF9"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_81_114">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Atrakcyjne i przejrzyste ceny',
    desc: 'Transparentne koszty, płacisz tylko za przestrzeń, której faktycznie używasz, bez ukrytych opłat.',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_81_148)">
          <path
            d="M36.881 19C36.881 20.8484 35.1328 22.3981 34.5913 24.066C34.03 25.7947 34.5141 28.0722 33.4669 29.5111C32.4093 30.9644 30.0865 31.2029 28.6333 32.2605C27.1944 33.3076 26.2556 35.449 24.5269 36.0103C22.859 36.5519 20.8485 35.389 19 35.389C17.1516 35.389 15.1411 36.5519 13.4732 36.0103C11.7445 35.449 10.8057 33.3077 9.36679 32.2605C7.91352 31.2028 5.5908 30.9644 4.53313 29.5111C3.48598 28.0722 3.97006 25.7947 3.40877 24.066C2.86725 22.3981 1.11902 20.8484 1.11902 19C1.11902 17.1516 2.86725 15.6018 3.40877 13.9339C3.97006 12.2053 3.48598 9.92774 4.53313 8.48887C5.5908 7.0356 7.91352 6.79718 9.36679 5.73951C10.8057 4.69236 11.7445 2.55106 13.4732 1.9897C15.1411 1.44811 17.1515 2.61097 19 2.61097C20.8485 2.61097 22.859 1.44811 24.5269 1.9897C26.2556 2.55099 27.1944 4.69229 28.6333 5.73951C30.0865 6.79718 32.4093 7.0356 33.4669 8.48887C34.5141 9.92774 34.03 12.2053 34.5913 13.9339C35.1328 15.6018 36.881 17.1515 36.881 19Z"
            stroke="#54ABF9"
            strokeWidth="2.23798"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 30.9318C25.5898 30.9318 30.9318 25.5897 30.9318 19C30.9318 12.4103 25.5898 7.06821 19 7.06821C12.4103 7.06821 7.06824 12.4103 7.06824 19C7.06824 25.5897 12.4103 30.9318 19 30.9318Z"
            stroke="#54ABF9"
            strokeWidth="2.23798"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.9809 14.9425C21.9809 14.9425 20.1469 13.4008 17.9841 14.0523C15.9983 14.6505 15.7205 16.9404 17.1572 17.8771C17.1572 17.8771 18.567 18.506 20.1307 19.0828C23.8947 20.4712 22.2733 24.1127 19.2431 24.1127C17.7257 24.1127 16.4522 23.4482 15.6814 22.5976"
            stroke="#54ABF9"
            strokeWidth="2.23798"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 24.1068V26.17"
            stroke="#54ABF9"
            strokeWidth="2.23798"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 11.83V13.8931"
            stroke="#54ABF9"
            strokeWidth="2.23798"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_81_148">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Pełne bezpieczeństwo Twoich rzeczy',
    desc: 'Całodobowy monitoring, ochrona oraz dostęp tylko dla upoważnionych osób gwarantują pełne bezpieczeństwo Twojego mienia.',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_81_171)">
          <mask
            id="mask0_81_171"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="38"
            height="38"
          >
            <path d="M0 3.8147e-06H38V38H0V3.8147e-06Z" fill="white" />
          </mask>
          <g mask="url(#mask0_81_171)">
            <path
              d="M19 36.8867C9.77453 34.2508 3.41406 25.7445 3.41406 16.1498V5.64063L19 1.11328L34.5859 5.64063V16.1498C34.5859 25.7445 28.2255 34.2508 19 36.8867Z"
              stroke="#54ABF9"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 27.9062C14.0892 27.9062 10.0938 23.9108 10.0938 19C10.0938 14.0892 14.0892 10.0938 19 10.0938C23.9108 10.0938 27.9062 14.0892 27.9062 19C27.9062 23.9108 23.9108 27.9062 19 27.9062Z"
              stroke="#54ABF9"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.6602 19L17.8867 21.2266L22.3398 16.7734"
              stroke="#54ABF9"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_81_171">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

export default function LandingWhyUsSection() {
  return (
    <section id="why-us" className={`flex flex-col pt-12 lg:pt-20 bg-accent`}>
      <div
        className={`flex flex-col-reverse xl:flex-row-reverse gap-8 pb-12 lg:pb-20 ${defaultStyles.paddingBig}`}
      >
        <img
          loading="lazy"
          alt="Why us image"
          className="w-[90%] xl:w-[50%] max-w-[691px] max-h-[662px] self-center xl:self-auto"
          src="/landing-why-us.png"
        />
        <div className="flex flex-col gap-4">
          <div className="uppercase text-primary font-[600] text-[16px]">
            Oszczędne i komfortowe przechowywanie rzeczy
          </div>
          <div className="text-secondary font-bold text-[24px] md:text-[35px]">
            Poznaj nas bliżej – Twoi eksperci od przestrzeni
          </div>
          <div className="flex flex-col gap-12 mt-8">
            <div className="font-[400] text-[20px] text-secondary leading-[34px]">
              W 4box.pl oferujemy elastyczny wynajem magazynów self-storage dla
              firm i osób prywatnych.
            </div>
            <div className="font-[400] text-[20px] text-secondary leading-[34px]">
              Zapewniamy całodobowo monitorowaną przestrzeń, dostępną na krótki
              lub długi termin, idealną podczas przeprowadzek, remontów czy
              sezonowych zmian.
            </div>
            <div className="font-[400] text-[20px] text-secondary leading-[34px]">
              Magazyny znajdują się na utwardzonym i oświetlonym placu o
              powierzchni 1200m<sup>2</sup>.
            </div>
            <div className="font-[400] text-[20px] text-secondary leading-[34px]">
              W naszej firmie stawiamy na jakość, innowacje oraz budowanie
              długotrwałych relacji opartych na zaufaniu, dzięki czemu jesteśmy
              w stanie spełnić indywidualne potrzeby wszystkich naszych
              kontrahentów.
            </div>
          </div>
        </div>
      </div>
      <div
        className={`bg-primary py-24 flex flex-row justify-center items-center md:justify-between md:items-start flex-wrap gap-8 ${defaultStyles.paddingBig}`}
      >
        {dots.map((item, index) => (
          <div className="flex flex-col gap-4 max-w-[430px]" key={index}>
            <div className="bg-neutral max-h-[109px] min-h-[109px] max-w-[109px] min-w-[109px] rounded-full flex flex-col justify-center items-center">
              {item.svg}
            </div>
            <div className="font-bold text-[18px] md:text-[24px] text-neutral">
              {item.title}
            </div>
            <div className="text-[14px] md:text-[16px] text-neutral">
              {item.desc}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
