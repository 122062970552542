import HeroSliderSection from '@/components/HeroSliderSection';
import LandingHeroSection from '@/components/LandingHeroSection';
import LandingHowToLeaseSection from '@/components/LandingHowToLeaseSection';
import LandingPricingSection from '@/components/LandingPricingSection';
import LandingWhyUsSection from '@/components/LandingWhyUsSection';
import MapSection from '@/components/MapSection';
import Navbar from '@/components/Navbar';
import Head from 'next/head';

export default function Home() {
  return (
    <>
      <Head>
        <title>4Box</title>
        <meta name="description" content="Strona główna firmy 4Box" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Navbar withoutHero />
      <div className="mt-[108px]" />
      <LandingHeroSection />
      <LandingHowToLeaseSection />
      <HeroSliderSection />
      <LandingWhyUsSection />
      <LandingPricingSection />
      <MapSection />
    </>
  );
}
