import { warehouses } from '@/constants';
import { defaultStyles } from '@/utils/defaultStyles';
import { Warehouse } from '@/utils/types';
import { useEffect, useState } from 'react';
import Button from './Button';

export default function LandingPricingSection() {
  const [selected, setSelected] = useState(1);
  const [selectedDetails, setSelectedDetails] = useState<
    Warehouse | undefined
  >();

  useEffect(() => {
    if (selected) {
      setSelectedDetails(warehouses.find((item) => item.id == selected));
    }
  }, [selected]);

  return (
    <section
      id="pricing"
      className={`flex flex-col gap-12 pt-12 lg:pt-20 ${defaultStyles.paddingBig}`}
    >
      <div className="flex flex-col lg:flex-row gap-8 justify-between items-start">
        <div className="flex flex-col gap-4">
          <div className="uppercase text-primary font-[600] text-[16px]">
            Przejrzysta oferta cenowa
          </div>
          <div className="text-secondary font-bold text-[24px] md:text-[35px]">
            Cennik wynajmu magazynów
          </div>
        </div>
        <div className="text-secondary text-[16px] lg:max-w-[50%] leading-[34px]">
          W 4BOX płacisz tylko za potrzebną przestrzeń, bez ukrytych kosztów.
          Oferujemy konkurencyjne ceny zarówno na krótki, jak i długi termin –
          wybierz opcję idealną dla siebiei przechowuj rzeczy bezpiecznie.
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        {warehouses.map((item, index) => (
          <div className="flex flex-row" key={index}>
            <div
              className={`w-[272px] h-[132px] flex flex-row gap-4 justify-center items-center bg-accent border-4 border-solid border-l-0 border-t-0 border-r-0 transition-all duration-300 cursor-pointer ${selected == item.id ? 'border-primary' : 'border-transparent hover:border-primary'}`}
              onClick={() => setSelected(item.id)}
            >
              <img
                className="w-[120px] h-[90px]"
                alt={item.name}
                src={item.smallImage}
                loading="lazy"
              />
              <div className="flex flex-col gap-2">
                <div className="text-[24px] text-secondary">Magazyn</div>
                <div className="font-bold text-[24px] text-secondary">
                  {item.size} m<sup>2</sup>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedDetails && (
        <div className={`flex flex-col-reverse xl:flex-row-reverse gap-8`}>
          <img
            loading="lazy"
            alt={selectedDetails.name}
            className="w-[90%] xl:w-[60%] self-center xl:self-auto"
            src={selectedDetails.smallImage}
          />
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-12 mt-8">
              <div className="text-[24px] md:text-[35px] text-secondary">
                {selectedDetails.name} -{' '}
                <span className="font-bold">
                  {selectedDetails.size} m<sup>2</sup>
                </span>
              </div>
              <div className="font-[400] text-[20px] text-secondary leading-[34px]">
                {selectedDetails.shortDesc}
              </div>
              <div className="flex flex-row justify-between items-center gap-8">
                <div className="flex flex-col gap-2">
                  <div className="font-bold text-[24px] md:text-[34px] text-primary">
                    {selectedDetails.price} zł brutto
                  </div>
                  <div className="text-[16px] text-secondary">/miesiąc</div>
                </div>
                <Button
                  label="Wynajmij magazyn"
                  primary
                  onClick={() => {
                    window.location.assign('/products/' + selectedDetails.id);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
