export const API_URL = 'https://api.4box.pl/api';
export const API_URL_MEDIA = 'https://api.4box.pl';
export const recaptchaSiteKey = '6LdKsooqAAAAACsjoMqAQ2Hl5fVxHFXT7Q1oz5FS';
export const QUERY_KEYS = Object.freeze({
  GET_POSTS: 'getPosts',
  GET_POST: 'getPost',
});

export const faq = [
  {
    title: 'Czym jest self-storage i dla kogo jest przeznaczony?',
    desc: 'Self-storage to usługa wynajmu magazynów o różnych rozmiarach, dostępnych dla firm i osób prywatnych. Jest idealnym rozwiązaniem, gdy potrzebujesz dodatkowej przestrzeni na czas przeprowadzki, remontu, sezonowego przechowywania rzeczy lub jako długoterminowy magazyn na przedmioty firmowe.',
  },
  {
    title: 'Jakie rozmiary magazynów są dostępne w 4box.pl?',
    desc: 'Oferujemy magazyny o różnych powierzchniach: 2 m², 3 m², 6 m², 9 m² i 12 m². Dzięki temu możesz wybrać przestrzeń dostosowaną do swoich potrzeb, bez konieczności płacenia za niewykorzystane miejsce.',
  },
  {
    title: 'Czy magazyny w 4box.pl są bezpieczne?',
    desc: 'Tak, zapewniamy wysoki poziom bezpieczeństwa. Nasz plac jest wyposażony w zaawansowany system monitoringu, alarmy oraz nadzorowany przez profesjonalną Agencję Ochrony. Dostęp do magazynów mają wyłącznie klienci lub osoby przez nich upoważnione.',
  },
  {
    title: 'Jak działa wynajem magazynów w 4box.pl?',
    desc: 'Proces jest prosty i elastyczny. Wybierasz odpowiedni magazyn, podpisujesz umowę na dowolny okres (krótko- lub długoterminowy), a następnie uzyskujesz całodobowy dostęp do swojej przestrzeni magazynowej.',
  },
  {
    title: 'Czy mogę korzystać z magazynu przez całą dobę?',
    desc: 'Tak, wszystkie magazyny 4box.pl są dostępne 24/7, dzięki czemu możesz wygodnie zarządzać swoimi rzeczami o każdej porze dnia i nocy.',
  },
  {
    title: 'Czy w magazynach można przechowywać każdy rodzaj rzeczy?',
    desc: 'Nasze magazyny są przystosowane do przechowywania większości rzeczy, takich jak meble, dokumenty, sprzęt sportowy czy narzędzia. Zabronione jest jednak przechowywanie przedmiotów łatwopalnych, niebezpiecznych, żywności czy innych materiałów zabronionych prawem.',
  },
  {
    title: 'Jakie są koszty wynajmu magazynu?',
    desc: 'Nasze ceny zaczynają się od 179 zł brutto za magazyn o powierzchni 2 m². Szczegółowy cennik dla większych powierzchni znajdziesz na naszej stronie w sekcji „Nasz cennik”.',
  },
  {
    title: 'Jak mogę zarezerwować magazyn w 4box.pl?',
    desc: 'Aby zarezerwować magazyn, skontaktuj się z nami za pomocą formularza kontaktowego na stronie, podając swoje dane oraz preferowaną wielkość magazynu. Możesz też zadzwonić bezpośrednio pod podany numer telefonu.',
  },
  {
    title: 'Czy magazyny są odporne na warunki atmosferyczne?',
    desc: 'Tak, nasze magazyny są w pełni zabezpieczone przed wilgocią, zalaniem oraz uszkodzeniami mechanicznymi. Dzięki temu Twoje rzeczy będą przechowywane w idealnych warunkach przez cały okres wynajmu.',
  },
];

export const warehouses = [
  {
    id: 1,
    route:
      'https://4box.sprytki.pl/rent?step=1&typeId=88e582a0-ae9b-ef11-88d0-000d3a145b56',
    name: 'Boks magazynowy',
    smallImage: '/products/2m/small.png',
    size: '2',
    price: '179,00',
    shortDesc:
      'Stworzony z myślą o osobach prywatnych, poszukujących przestrzeni składowej dla sprzętu domowego lub sportowego, narzędzi i akcesoriów ogrodniczych. ',
    detailedDesc: {
      title1: (
        <>
          <span className="font-bold">
            Magazyn 2 m² - Sprawdzone rozwiązanie
          </span>{' '}
        </>
      ),
      desc1: (
        <>
          Dzięki niewielkim rozmiarom świetnie uzupełni teren zielony twojego
          domu, nie zakłócając jego harmonii.
          <br />
          <br />
          Szerokie wejście do magazynu sprawia, że przestrzeń jest w całości
          dostępna i prosta do zagospodarowania, a umieszczone w niej przedmioty
          są na wyciągnięcie ręki. Magazyn został zaprojektowany tak, aby
          pomieścił kluczowe przedmioty codziennego użytku, a jego model sprosta
          również przechowaniu motocyklu.
        </>
      ),
      title2: (
        <>
          <span className="font-bold">Dlaczego warto wybrać magazyn 2 m²?</span>{' '}
        </>
      ),
      checkmarks: [
        'Posiada szerokie wejście, dzięki któremu zapewni szybki i prosty dostęp do mienia.',
        'Idealny dla osób prywatnych poszukujących dodatkowego miejsca na rzadziej używane przedmioty.',
        'Będzie odgrywał świetne uzupełnienie ogrodu.',
        'Stanowi ułatwienie składowania sprzętu w trakcie domowego remontu.',
      ],
    },
    specs: {
      image: '/products/2m/specs.png',
      height: '2,10',
      width: '0,95',
      depth: '2,10',
    },
  },
  {
    id: 2,
    route:
      'https://4box.sprytki.pl/rent?step=1&typeId=830974be-ae9b-ef11-88d0-000d3a145b56',
    name: 'Boks magazynowy',
    smallImage: '/products/3m/small.png',
    size: '3',
    price: '219,00',
    shortDesc:
      'Jest definicją elastyczności. Idealnie sprawdzi się przy przechowywaniu większej ilości przedmiotów bądź urządzeń domowych takich jak pralka czy lodówka. ',
    detailedDesc: {
      title1: (
        <>
          <span className="font-bold">Niezawodna kompaktowość</span>{' '}
        </>
      ),
      desc1: (
        <>
          Jeżeli planujesz sezonowe porządki, magazyn o powierzchni 3 m² będzie
          twoim pomocnikiem. Pomieści przedmioty o sporych gabarytach, rowery
          czy narzędzia warsztatowe. Szerokie drzwi kontenerów ułatwiają dostęp
          do wszystkich przechowywanych rzeczy, co pozwala na efektywne
          wykorzystanie powierzchni. Sezonowe porządki nie muszą już spędzać snu
          z powiek, albowiem wszelkie meble ogrodowe znajdą swoje miejsce
          właśnie w magazynie 3 m².
        </>
      ),
      title2: (
        <>
          <span className="font-bold">Magazyn 3 m² sprosta oczekiwaniom:</span>{' '}
        </>
      ),
      checkmarks: [
        'Pomieści sprzęty AGD takie jak lodówka czy zmywarka.',
        'Większa przestrzeń równoznaczna z większymi gabarytami do przechowania.',
        'Rozwiązanie dla osób poszukujących miejsca do sezonowego składowania dobytku.',
      ],
    },
    specs: {
      image: '/products/3m/specs.png',
      height: '2,10',
      width: '1,50',
      depth: '2,10',
    },
  },
  {
    id: 3,
    route:
      'https://4box.sprytki.pl/rent?step=1&typeId=c60761ee-ae9b-ef11-88d0-000d3a145b56',
    name: 'Boks magazynowy',
    smallImage: '/products/6m/small.png',
    size: '6',
    price: '339,00',
    shortDesc:
      'Ten magazyn średniej wielkości sprosta potrzebom przechowania dużych sprzętów AGD czy kilku mniejszych jak kosiarka albo skuter. Na czas przeprowadzki, remontu lub wyjazdu zorganizuje przestrzeń dla wszystkich ważnych przedmiotów z mieszkania.',
    detailedDesc: {
      title1: (
        <>
          <span className="font-bold">Rozmiar w sam raz</span>{' '}
        </>
      ),
      desc1: (
        <>
          Dzięki łatwemu dostępowi do wnętrza magazynu, przeniesienie mienia
          jest szybkie i wygodne. Jest to wybór idealny dla osób planujących
          większe zmiany w domu lub biurze.
          <br />
          <br />
          Boks magazynowy 6 m² z łatwością pomieści zarówno meble, jak i większe
          narzędzia czy sprzęt domowy.
          <br />
          <br />
          Można go zaaranżować według własnych potrzeb, dodając półki lub
          regały, co jeszcze bardziej ułatwia jego organizację.
        </>
      ),
      title2: (
        <>
          <span className="font-bold">
            Dlaczego magazyn 6 m² będzie rozwiązaniem dla Ciebie?
          </span>{' '}
        </>
      ),
      checkmarks: [
        'Umożliwia dopasowanie przestrzeni pod własne potrzeby.',
        'Jest niezbędnym elementem każdej przeprowadzki - zapewnia przestrzeń Twoim meblom i sprzętom narażonym na uszczerbek w trakcie transportu.',
      ],
    },
    specs: {
      image: '/products/6m/specs.png',
      height: '2,10',
      width: '2,10',
      depth: '3,00',
    },
  },
  {
    id: 4,
    route:
      'https://4box.sprytki.pl/rent?step=1&typeId=c2ff5018-af9b-ef11-88d0-000d3a145b56',
    name: 'Boks magazynowy',
    smallImage: '/products/9m/small.png',
    size: '9',
    price: '429,00',
    shortDesc:
      'Jest rozwiązaniem na problem z przechowywaniem większych materiałów, sprzętów AGD i mebli. Będzie również świetną opcją dla małych firm, które potrzebują miejsca do składowania towarów.',
    detailedDesc: {
      title1: (
        <>
          <span className="font-bold">Solidne standardy</span>{' '}
        </>
      ),
      desc1: (
        <>
          Wysokie wnętrze oraz szerokie wejście ułatwiają przenoszenie rzeczy, a
          przestrzeń możesz zorganizować zgodnie z własnymi potrzebami.
          <br />
          <br />
          Oferowany Boks magazynowy to również świetne rozwiązanie dla osób,
          które potrzebują długoterminowej przechowalni dla sezonowych
          przedmiotów, takich jak rowery, narty czy sprzęt ogrodowy.
          <br />
          <br />
          To bezkonkurencyjny wybór zarówno dla właścicieli firm, jak i klientów
          indywidualnych, ceniących sobie wygodę idącą w parze z jakością.
        </>
      ),
      title2: (
        <>
          <span className="font-bold">
            Magazyn o powierzchni 9 m² to połączenie:
          </span>{' '}
        </>
      ),
      checkmarks: [
        'Uniwersalności - sprawdzi się równie dobrze jako zaplecze towarowe i magazyn na meble czy sprzęty sezonowe.',
        'Komfortu - jego budowa pozwala na zamontowanie potrzebnych regałów aby dopasować się do Twoich wymagań.',
      ],
    },
    specs: {
      image: '/products/9m/specs.png',
      height: '2,10',
      width: '2,10',
      depth: '4,50',
    },
  },
  {
    id: 5,
    route:
      'https://4box.sprytki.pl/rent?step=1&typeId=0e9ad03c-af9b-ef11-88d0-000d3a145b56',
    name: 'Boks magazynowy',
    smallImage: '/products/12m/small.png',
    size: '12',
    price: '549,00',
    shortDesc:
      'Numer jeden dla wymagających firm. Duża przestrzeń bowiem posłuży za magazyn towarowy, pomieści wyposażenie biurowe, a nawet pojazdy takie jak motocykle czy quady.',
    detailedDesc: {
      title1: (
        <>
          <span className="font-bold">Przestrzeń bez ograniczeń</span>{' '}
        </>
      ),
      desc1: (
        <>
          Magazyn o powierzchni 12 m² to prawdziwy gigant wśród przestrzeni do
          wynajęcia, idealny dla najbardziej wymagających użytkowników.
          <br />
          <br />
          Dzięki swojej wielkości kontener ten może pełnić funkcję małego
          magazynu firmowego lub zaplecza do przechowywania większych
          materiałów.
          <br />
          <br />
          Szerokie wejście umożliwia łatwe wnoszenie i wynoszenie dużych
          materiałów. Miejsce w którym znajduje się kontener jest oświetlone,
          ogrodzone i pod stałym monitoringiem.
        </>
      ),
      title2: (
        <>
          <span className="font-bold">
            Spośród zalet magazynu 12 m² wymieniamy:
          </span>{' '}
        </>
      ),
      checkmarks: [
        'Wysokie i przestronne wnętrze umożliwiające szeroki dostęp do przechowywanych rzeczy.',
        'Tworzy rozwiązanie dla posiadaczy firm poszukujących małego magazynu lub zaplecza do przechowywania większych materiałów.',
        'Powierzchnia, która pomieści duże gabaryty, bez podziału na meble, pojazdy czy towary.',
        'Całodobowy monitoring Twojego dobytku.',
      ],
    },
    specs: {
      image: '/products/12m/specs.png',
      height: '2,10',
      width: '2,10',
      depth: '6,00',
    },
  },
];
