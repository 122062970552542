export default function Button(props: {
  label: string;
  onClick: () => void;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  accent?: boolean;
  class?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}) {
  let styles = '';

  if (props.primary) {
    styles =
      'bg-primary text-neutral border-primary stroke-neutral hover:stroke-primary hover:bg-neutral hover:text-primary';
  }
  if (props.secondary) {
    styles =
      'bg-transparent text-primary border-primary hover:bg-primary hover:text-neutral';
  }
  if (props.tertiary) {
    styles =
      'bg-primary text-neutral border-neutral hover:bg-neutral hover:text-primary';
  }
  if (props.accent) {
    styles =
      'bg-accent text-primary border-accent hover:bg-transparent hover:text-accent';
  }

  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type}
      className={`w-[230px] h-[57px] outline-none border-solid transition-all duration-300 ${props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'} text-[16px] font-[500] ${styles} ${props.class}`}
    >
      <div className="text-inherit stroke-inherit flex flex-row gap-4 justify-between items-center px-4">
        <div className="text-inherit">{props.label}</div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="stroke-inherit"
        >
          <path
            d="M12.025 4.94165L17.0834 9.99998L12.025 15.0583"
            className="stroke-inherit"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.91669 10H16.9417"
            className="stroke-inherit"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </button>
  );
}
