import { useEffect } from 'react';
import Script from 'next/script';

const TrackingScripts = () => {
  useEffect(() => {
    // Inicjalizacja Facebook Pixel
    if (typeof window !== 'undefined' && !window.fbq) {
      const fbq = function (...args) {
        fbq.callMethod ? fbq.callMethod(...args) : fbq.queue.push(args);
      };

      fbq.queue = [];
      fbq.loaded = true;
      fbq.version = '2.0';
      window.fbq = fbq;

      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://connect.facebook.net/en_US/fbevents.js';
      document.getElementsByTagName('head')[0].appendChild(script);

      window.fbq('init', '1782502925901085');
      window.fbq('track', 'PageView');
    }
  }, []);

  return (
    <>
      {/* Facebook Pixel noscript fallback */}
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=1782502925901085&ev=PageView&noscript=1"
          alt="Facebook Pixel"
        />
      </noscript>

      {/* Google Tag Manager */}
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-VML32F1DF6"
      />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-VML32F1DF6');
        `}
      </Script>
    </>
  );
};

export default TrackingScripts;
