import API from '@/api';
import { defaultStyles } from '@/utils/defaultStyles';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from './Button';
import { FormikFormField } from './FormikFormField';
import { recaptchaSiteKey } from '@/constants';
import ReCAPTCHA from 'react-google-recaptcha';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Imię i nazwisko są wymagane'),
  phone: Yup.string().required('Numer telefonu jest wymagany'),
  email: Yup.string().email().required('Email jest wymagany'),
  agreements: Yup.boolean().oneOf([true], 'Zgody są wymagane'),
  message: Yup.string().required('Wiadomość jest wymagana'),
});

export default function ContactFormSection() {
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  return (
    <section
      id="contact-form"
      className={`flex flex-col gap-12 py-12 lg:py-20 bg-accent ${defaultStyles.paddingBig}`}
    >
      <div className="flex flex-col lg:flex-row gap-8 justify-between items-start">
        <div className="flex flex-col gap-4">
          <div className="uppercase text-primary font-[600] text-[16px]">
            Masz pytania? Jesteśmy do dyspozycji
          </div>
          <div className="text-secondary font-bold text-[24px] md:text-[35px] lg:max-w-[90%]">
            Skontaktuj się, aby dowiedzieć się więcej o naszej ofercie
          </div>
        </div>
        <div className="text-secondary text-[16px] lg:max-w-[50%] leading-[34px]">
          Nasz zespół chętnie odpowie na Twoje pytania i pomoże dobrać idealne
          rozwiązanie magazynowe. Skontaktuj się z nami telefonicznie, mailowo
          lub przez formularz.
        </div>
      </div>
      <Formik
        initialValues={{
          name: '',
          phone: '',
          email: '',
          message: '',
          agreements: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          await toast.promise(
            API.post('/mail/contact', {
              name: values.name,
              phone: values.phone,
              email: values.email,
              message: values.message,
            }),
            {
              pending: 'Wysyłanie...',
              success: 'Wysłano e-mail',
              error: 'Wystąpił błąd przy wysyłaniu.',
            },
          );
          resetForm();
          setRecaptchaToken(null);
        }}
      >
        {({ values, errors, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={`flex flex-col gap-4`}>
            <div className={`flex flex-col gap-4`}>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <FormikFormField
                  label="Imię i nazwisko*"
                  placeholder="Wpisz imię i nazwisko"
                  name="name"
                  value={values.name}
                />
                <FormikFormField
                  label="E-Mail*"
                  placeholder="Wpisz e-mail"
                  name="email"
                  value={values.email}
                />
                <FormikFormField
                  label="Numer telefonu*"
                  placeholder="Wpisz numer telefonu"
                  name="phone"
                  value={values.phone}
                />
              </div>
              <FormikFormField
                label="Wiadomość*"
                type="textarea"
                placeholder="Zacznij pisać..."
                name="message"
                value={values.message}
              />
              <div className="grid grid-cols-1 gap-4">
                <label className="text-[12px]">
                  <Field type="checkbox" name="agreements" />
                  Wyrażam zgodę na przetwarzanie moich danych osobowych w celach
                  marketingowych i promocyjnych przez MEVA HOME SPÓŁKA Z
                  OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ, NIP: 5833493986, z siedzibą
                  przy ul. Nieborowska 37/6, 80-034 Gdańsk Osowa, Polska.
                </label>
              </div>
              <ReCAPTCHA
                sitekey={recaptchaSiteKey}
                onChange={(token: string | null) => setRecaptchaToken(token)}
                onExpired={() => setRecaptchaToken(null)} // Reset token on expiration
              />
              {Object.keys(errors).length > 0 && (
                <div className="text-red-500 text-xs">
                  <ul>
                    {Object.values(errors).map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
              <Button
                type="submit"
                label="Wyślij"
                primary
                onClick={handleSubmit}
                disabled={!recaptchaToken || isSubmitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </section>
  );
}
