import dynamic from 'next/dynamic';

const Map = dynamic(() => import('@/components/Map'), {
  ssr: false,
});

export default function MapSection() {
  return (
    <section className={`flex flex-col justify-center items-center`} id="map">
      <Map />
    </section>
  );
}
