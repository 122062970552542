export const CustomRightArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <div
      className={`absolute top-0 right-0 rounded-full w-[38px] h-[38px] border border-solid border-primary flex flex-col justify-center items-center cursor-pointer bg-neutral hover:bg-primary stroke-primary hover:stroke-neutral transition-all duration-300`}
      onClick={() => onClick()}
    >
      <svg
        width="10"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-inherit"
      >
        <path
          d="M1.47998 15.0599L8.51998 7.99994L1.47998 0.939941"
          className="stroke-inherit"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const CustomLeftArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <div
      className={`absolute top-0 right-14 rounded-full w-[38px] h-[38px] border border-solid border-primary flex flex-col justify-center items-center cursor-pointer bg-neutral hover:bg-primary stroke-primary hover:stroke-neutral transition-all duration-300`}
      onClick={() => onClick()}
    >
      <svg
        width="10"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-inherit"
      >
        <path
          d="M8.52002 15.0599L1.48002 7.99994L8.52002 0.939941"
          className="stroke-inherit"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const CustomDot = ({ onMove, index, onClick, active }: any) => {
  return (
    <li
      className={`mb-6 ${active ? 'active' : 'inactive'}`}
      onClick={() => onClick()}
    >
      <div
        className={`h-[2px] w-[48px] mx-2 transition-all duration-300 ${active ? 'bg-primary' : 'bg-secondary bg-opacity-30'}`}
      ></div>
    </li>
  );
};
