import { defaultStyles } from '@/utils/defaultStyles';
import { routes } from './Navbar';
import { warehouses } from '@/constants';
import Divider from './Divider';

function Footer() {
  return (
    <footer
      className={`bg-primary flex flex-col gap-8 py-12 ${defaultStyles.paddingBig}`}
    >
      <div className="flex flex-row justify-between flex-wrap gap-8">
        <div className="flex flex-col gap-6">
          <img
            alt="4box logo"
            src="/logo-white.png"
            width={143}
            height={45}
            loading="lazy"
          />
          <div className="flex flex-row items-center gap-2">
            <svg
              width="15"
              height="20"
              viewBox="0 0 15 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 0C5.51169 0.00263754 3.60556 0.79366 2.19961 2.19961C0.793661 3.60556 0.00263755 5.51168 0 7.49999C0 12.8844 6.9875 19.6281 7.28437 19.9125C7.34212 19.9686 7.41947 20 7.5 20C7.58053 20 7.65788 19.9686 7.71562 19.9125C8.0125 19.6281 15 12.8844 15 7.49999C14.9974 5.51168 14.2063 3.60556 12.8004 2.19961C11.3944 0.79366 9.48831 0.00263754 7.5 0ZM7.5 10.9375C6.82013 10.9375 6.15552 10.7359 5.59023 10.3582C5.02493 9.98045 4.58434 9.44359 4.32416 8.81546C4.06399 8.18734 3.99591 7.49618 4.12855 6.82937C4.26119 6.16256 4.58858 5.55006 5.06932 5.06931C5.55006 4.58857 6.16257 4.26118 6.82938 4.12855C7.49619 3.99591 8.18735 4.06398 8.81547 4.32416C9.4436 4.58434 9.98046 5.02493 10.3582 5.59022C10.7359 6.15551 10.9375 6.82012 10.9375 7.49999C10.937 8.41151 10.5746 9.28553 9.93008 9.93007C9.28554 10.5746 8.41151 10.9369 7.5 10.9375Z"
                fill="white"
              />
            </svg>
            <div className="text-[16px] text-neutral">
              ul. Galaktyczna 325, Gdańsk Osowa, Polska
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.9995 13.7278V16.2869C18.0004 16.5245 17.9517 16.7597 17.8563 16.9774C17.7609 17.1951 17.6211 17.3905 17.4457 17.5511C17.2702 17.7117 17.0632 17.834 16.8376 17.9101C16.6121 17.9862 16.3732 18.0145 16.1361 17.9931C13.5059 17.7078 10.9794 16.8109 8.75957 15.3742C6.69433 14.0644 4.94337 12.3169 3.63104 10.2558C2.18648 8.03034 1.28751 5.49657 1.00694 2.85976C0.985578 2.62386 1.01367 2.3861 1.08942 2.16163C1.16518 1.93716 1.28693 1.73089 1.44694 1.55596C1.60694 1.38102 1.80169 1.24125 2.01879 1.14555C2.23589 1.04985 2.47057 1.00031 2.7079 1.00008H5.27217C5.68699 0.996008 6.08914 1.14261 6.40366 1.41257C6.71818 1.68252 6.92362 2.05741 6.98168 2.46735C7.08991 3.28634 7.29063 4.09049 7.58001 4.86445C7.69501 5.16978 7.7199 5.50162 7.65173 5.82063C7.58355 6.13965 7.42518 6.43247 7.19537 6.66441L6.10983 7.7478C7.32662 9.88348 9.09844 11.6518 11.2384 12.8662L12.3239 11.7828C12.5563 11.5534 12.8497 11.3954 13.1694 11.3273C13.489 11.2593 13.8215 11.2841 14.1274 11.3989C14.9029 11.6877 15.7087 11.888 16.5293 11.996C16.9445 12.0545 17.3237 12.2632 17.5948 12.5825C17.8658 12.9018 18.0099 13.3094 17.9995 13.7278Z"
                stroke="white"
                strokeWidth="1.71961"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div className="text-[16px] text-neutral">+48 500 780 880</div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <svg
              width="20"
              height="17"
              viewBox="0 0 20 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.8 1H17.2C18.19 1 19 1.81036 19 2.80081V13.6056C19 14.5961 18.19 15.4065 17.2 15.4065H2.8C1.81 15.4065 1 14.5961 1 13.6056V2.80081C1 1.81036 1.81 1 2.8 1Z"
                stroke="white"
                strokeWidth="1.71961"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 2.80078L10 9.1036L1 2.80078"
                stroke="white"
                strokeWidth="1.71961"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div className="text-[16px] text-neutral">biuro@4box.pl</div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="uppercase text-[14px] text-neutral font-bold">
            Firma
          </div>
          {routes.map((item, index) => (
            <div
              key={index}
              className="text-neutral text-[16px] font-[300] cursor-pointer"
              onClick={() => {
                if (item.route) {
                  window.location.assign(item.route);
                  return;
                }
                if (item.id) {
                  setTimeout(() => {
                    const element = document.getElementById(item.id);
                    if (element) {
                      window.scrollTo({
                        top: element.offsetTop - 110,
                        behavior: 'smooth',
                      });
                    }
                  }, 0);
                  return;
                }
              }}
            >
              {item.title}
            </div>
          ))}
          <div
            className="text-neutral text-[16px] font-[300] cursor-pointer"
            onClick={() => window.location.assign('/privacy')}
          >
            Polityka prywatności
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="uppercase text-[14px] text-neutral font-bold">
            Oferta
          </div>
          {warehouses.map((item, index) => (
            <div
              key={index}
              className="text-neutral text-[16px] font-[300] cursor-pointer"
              onClick={() => window.location.assign('/products/' + item.id)}
            >
              Magazyn {item.size}m<sup>2</sup>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-4">
          <div className="uppercase text-[14px] text-neutral font-bold">
            Social media
          </div>
          <div className="flex flex-row items-center gap-2">
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1_492)">
                <path
                  d="M23.0047 11.5023C23.0047 17.2436 18.7982 22.0023 13.2996 22.8649V14.8272H15.9797L16.4897 11.5023H13.2996V9.34475C13.2996 8.4349 13.7453 7.54841 15.1741 7.54841H16.6245V4.71776C16.6245 4.71776 15.308 4.4931 14.0495 4.4931C11.4224 4.4931 9.7051 6.08546 9.7051 8.96823V11.5023H6.78458V14.8272H9.7051V22.8649C4.20644 22.0023 0 17.2436 0 11.5023C0 5.14999 5.14999 0 11.5023 0C17.8547 0 23.0047 5.14999 23.0047 11.5023Z"
                  fill="white"
                />
                <path
                  d="M15.9797 14.8273L16.4896 11.5024H13.2995V9.34477C13.2995 8.43514 13.7452 7.54847 15.174 7.54847H16.6244V4.71782C16.6244 4.71782 15.3081 4.49316 14.0497 4.49316C11.4223 4.49316 9.70506 6.08552 9.70506 8.96829V11.5024H6.78455V14.8273H9.70506V22.865C10.2907 22.9568 10.8909 23.0047 11.5023 23.0047C12.1137 23.0047 12.7139 22.9568 13.2995 22.865V14.8273H15.9797Z"
                  fill="#54ABF9"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_492">
                  <rect width="23.0047" height="23.0047" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div
              className="text-[16px] text-neutral cursor-pointer"
              onClick={() =>
                window.open(
                  'https://www.facebook.com/people/4boxpl/61565757911136/',
                  '_blank',
                )
              }
            >
              Facebook
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1789 23.8203C18.6082 23.8203 23.8202 18.6083 23.8202 12.1791C23.8202 5.74979 18.6082 0.537842 12.1789 0.537842C5.74967 0.537842 0.53772 5.74979 0.53772 12.1791C0.53772 18.6083 5.74967 23.8203 12.1789 23.8203Z"
                fill="white"
              />
              <path
                d="M14.6521 5.71167H9.71103C7.50696 5.71167 5.7168 7.50183 5.7168 9.7059V14.647C5.7168 16.851 7.50696 18.6412 9.71103 18.6412H14.6521C16.8561 18.6412 18.6463 16.851 18.6463 14.647V9.7059C18.6463 7.50183 16.8561 5.71167 14.6521 5.71167ZM17.2028 14.6521C17.2028 16.0594 16.0594 17.208 14.6469 17.208H9.70585C8.29856 17.208 7.14996 16.0646 7.14996 14.6521V9.71108C7.14996 8.30378 8.29339 7.15518 9.70585 7.15518H14.6469C16.0542 7.15518 17.2028 8.29861 17.2028 9.71108V14.6521Z"
                fill="#54ABF9"
              />
              <path
                d="M12.1789 8.8728C10.3577 8.8728 8.8728 10.3577 8.8728 12.1789C8.8728 14.0001 10.3577 15.485 12.1789 15.485C14.0001 15.485 15.485 14.0001 15.485 12.1789C15.485 10.3577 14.0001 8.8728 12.1789 8.8728ZM12.1789 14.1864C11.0717 14.1864 10.1714 13.2861 10.1714 12.1789C10.1714 11.0717 11.0717 10.1714 12.1789 10.1714C13.2861 10.1714 14.1864 11.0717 14.1864 12.1789C14.1864 13.2861 13.2861 14.1864 12.1789 14.1864Z"
                fill="#54ABF9"
              />
              <path
                d="M15.7369 9.2353C16.0415 9.18593 16.2484 8.89898 16.199 8.59438C16.1496 8.28979 15.8627 8.0829 15.5581 8.13227C15.2535 8.18165 15.0466 8.4686 15.096 8.77319C15.1454 9.07779 15.4323 9.28468 15.7369 9.2353Z"
                fill="#54ABF9"
              />
            </svg>

            <div className="text-[16px] text-neutral">Instagram</div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex flex-row gap-8 justify-between">
        <div className="text-[16px] text-neutral text-[400]">
          ©{new Date().getFullYear()} - 4box.pl - wszelkie prawa zastrzeżone
        </div>
        <div className="text-[16px] text-neutral text-[400]">
          Projekt i wykonanie - Askay
        </div>
      </div>
    </footer>
  );
}

export default Footer;
