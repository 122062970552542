import { defaultStyles } from '@/utils/defaultStyles';

const cards = [
  {
    title: 'Wybierz odpowiedni rozmiar magazynu',
    desc: 'Zastanów się, ile miejsca potrzebujesz i co chcesz przechowywać.',
    svg: (
      <svg
        width="38px"
        height="38px"
        viewBox="0 0 15 15"
        version="1.1"
        id="warehouse"
        fill="#ffffff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5,5c-0.0762,0.0003-0.1514-0.0168-0.22-0.05L7.5,2L1.72,4.93C1.4632,5.0515,1.1565,4.9418,1.035,4.685&#xA;&#x9;S1.0232,4.1215,1.28,4L7.5,0.92L13.72,4c0.2761,0.0608,0.4508,0.3339,0.39,0.61C14.0492,4.8861,13.7761,5.0608,13.5,5z M5,10H2v3h3&#xA;&#x9;V10z M9,10H6v3h3V10z M13,10h-3v3h3V10z M11,6H8v3h3V6z M7,6H4v3h3V6z"
        />
      </svg>
    ),
  },
  {
    title: 'Zarezerwuj od razu',
    desc: 'W systemie określ długość najmu i zapłać bezpiecznie online.',
    svg: (
      <svg
        width="38px"
        height="38px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 2a1 1 0 0 1 1 1v1h4V3a1 1 0 1 1 2 0v1h3a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h3V3a1 1 0 0 1 1-1zM8 6H5v3h14V6h-3v1a1 1 0 1 1-2 0V6h-4v1a1 1 0 0 1-2 0V6zm11 5H5v8h14v-8z"
          fill="#ffffff"
        />
      </svg>
    ),
  },
  {
    title: 'Weź kłódkę i przywieź swoje rzeczy',
    desc: 'Możesz przywieźć je samodzielnie lub skorzystać z firmy transportowej.',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.2461 18.0012V12.2461C31.2461 5.4936 25.7525 0 19 0C12.2475 0 6.75391 5.4936 6.75391 12.2461V18.0012C5.31785 19.5045 4.52734 21.4642 4.52734 23.5273C4.52734 31.5076 11.0198 38 19 38C26.9802 38 33.4727 31.5076 33.4727 23.5273C33.4727 21.4642 32.6822 19.5045 31.2461 18.0012ZM8.98047 12.2461C8.98047 6.72132 13.4752 2.22656 19 2.22656C24.5248 2.22656 29.0195 6.72132 29.0195 12.2461V16.3607C28.3178 16.016 27.5689 15.7824 26.793 15.6679V12.2461C26.793 7.94905 23.297 4.45312 19 4.45312C14.703 4.45312 11.207 7.94905 11.207 12.2461V15.6679C10.4311 15.7824 9.68221 16.016 8.98047 16.3607V12.2461ZM24.5664 12.2461V15.5859H13.4336V12.2461C13.4336 9.17678 15.9307 6.67969 19 6.67969C22.0693 6.67969 24.5664 9.17678 24.5664 12.2461ZM19 35.7734C12.2475 35.7734 6.75391 30.2798 6.75391 23.5273C6.75391 20.4296 9.30302 17.8125 12.3203 17.8125H25.6797C28.697 17.8125 31.2461 20.4296 31.2461 23.5273C31.2461 30.2798 25.7525 35.7734 19 35.7734Z"
          fill="white"
        />
        <path
          d="M19 20.1875C17.1584 20.1875 15.6602 21.6858 15.6602 23.5273C15.6602 24.9787 16.5909 26.2161 17.8867 26.6757V32.4336C17.8867 33.0484 18.3852 33.5469 19 33.5469C19.6148 33.5469 20.1133 33.0484 20.1133 32.4336V26.6757C21.4091 26.2161 22.3398 24.9787 22.3398 23.5273C22.3398 21.6858 20.8416 20.1875 19 20.1875ZM19 24.6406C18.3861 24.6406 17.8867 24.1412 17.8867 23.5273C17.8867 22.9135 18.3861 22.4141 19 22.4141C19.6139 22.4141 20.1133 22.9135 20.1133 23.5273C20.1133 24.1412 19.6139 24.6406 19 24.6406Z"
          fill="white"
        />
      </svg>
    ),
  },
];

export default function LandingHowToLeaseSection() {
  return (
    <section
      id="how-to-lease"
      className={`flex flex-col gap-12 py-12 lg:pt-20 bg-accent`}
    >
      <div
        className={`flex flex-col-reverse xl:flex-row-reverse gap-8 ${defaultStyles.paddingBig}`}
      >
        <div className="flex flex-col gap-4">
          <div className="uppercase text-primary font-[600] text-[16px]">
            Łatwy wynajem przestrzeni magazynowej
          </div>
          <div className="text-secondary font-bold text-[24px] md:text-[35px]">
            Jak wynająć magazyn w 4BOX?
          </div>
          <div className="flex flex-col lg:flex-row gap-4 mt-8">
            {cards.map((item, index) => (
              <div
                key={index}
                className="flex flex-col gap-4 px-4 py-8 bg-neutral"
              >
                <div className="bg-primary rounded-full max-w-[84px] min-w-[84px] max-h-[84px] min-h-[84px] flex flex-col justify-center items-center">
                  {item.svg}
                </div>
                <div className="font-bold text-secondary text-[18px] md:text-[24px]">
                  {item.title}
                </div>
                <div className="text-secondary text-[14px] md:text-[16px]">
                  {item.desc}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
