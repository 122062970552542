import { ReactNode } from 'react';

import Footer from './Footer';
import ContactFormSection from './ContactFormSection';
import TrackingScripts from './TrackingScripts';

type AppWrapperType = {
  children: ReactNode;
};

const AppWrapper = ({ children }: AppWrapperType) => {
  return (
    <div>
      <TrackingScripts />
      <main>{children}</main>
      <ContactFormSection />
      <Footer />
    </div>
  );
};

export default AppWrapper;
