import { AxiosInstance } from 'axios';
import { API_URL } from '@/constants';
import axios from 'axios';

export function ensureHttpsProtocol(url: string) {
  if (url.startsWith('http:')) {
    return url.replace(/^http:/, 'https:');
  } else {
    return url;
  }
}

// Token API Konwersji
const CONVERSION_API_TOKEN =
  'EAARWXgfczwUBO3Ie6Fdx8Xo6vqzaZAptpCVZAtxtmxjOGldiqD7SrvJgucTSQmts9cn2d5yQE3aqjZAkhy81plW0rBvfjM1RHJ1f2VyEGPyJ9xlZCXzYwZAq16ZBG0bZCisp5RmgPKUwQdJB7bNlzZBxQD4MTqh8ZBYrryVafZCuiuDGrqLzabjaQ9IPUOLS9qekmHgwZDZD';

export const API: AxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    Authorization: `Bearer ${CONVERSION_API_TOKEN}`, // Dodaj token do nagłówków
  },
});

export default API;
