import type { AppProps } from 'next/app';
import 'react-multi-carousel/lib/styles.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../styles/globals.css';

import CookieConsent from 'react-cookie-consent';
import AppWrapper from '../components/AppWrapper';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/utils/queryClient';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppWrapper>
          <Component {...pageProps} />
          <CookieConsent
            location="bottom"
            buttonText="Zaakceptuj pełne cookies"
            cookieName="4BoxCookie"
            style={{ background: '#2B373B' }}
            enableDeclineButton
            declineButtonText="Zaakceptuj tylko obowiązkowe cookies"
            declineButtonStyle={{
              backgroundColor: '#141414',
              color: '#fff',
              fontSize: '14px',
            }}
            buttonStyle={{
              backgroundColor: '#008000',
              color: '#fff',
              fontSize: '14px',
            }}
            containerClasses="flex flex-col"
            expires={150}
            buttonWrapperClasses="mt-0"
            contentStyle={{ flex: 0 }}
          >
            Nasza strona korzysta z plików cookies, aby zapewnić jak najlepsze
            działanie serwisu oraz dostosować treści do Twoich potrzeb. Możesz
            wybrać, które kategorie plików cookies chcesz zaakceptować:
            <br />
            <br />
            Obowiązkowe cookies
            <br />
            Są niezbędne do prawidłowego działania serwisu. Dzięki nim możemy
            zagwarantować podstawowe funkcjonalności, takie jak nawigacja na
            stronie czy bezpieczne logowanie.
            <br />
            <br />
            Pełne cookies
            <br />
            Obejmuje zarówno obowiązkowe cookies, jak i te używane do celów
            analitycznych, marketingowych oraz personalizacji treści. Akceptacja
            pełnych cookies pozwala nam lepiej dopasować ofertę do Twoich
            preferencji.
            <br />
            <br />
            Wybierz jedną z opcji:
          </CookieConsent>
        </AppWrapper>
        <ToastContainer />
      </QueryClientProvider>
    </>
  );
}
