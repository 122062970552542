import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import Button from './Button';
import { defaultStyles } from '@/utils/defaultStyles';

export default function LandingHeroSection() {
  return (
    <section
      id="landing-hero"
      className={`flex flex-col gap-8 py-12 lg:py-24 ${defaultStyles.paddingBig}`}
      style={createStyleForBackgroundImage('/landing-hero.png')}
    >
      <div className="flex flex-col gap-4">
        <div className="text-secondary font-[300] text-[18px] md:text-[25px]">
          Brakuje Ci miejsca na swoje rzeczy?
        </div>
        <div className="text-secondary font-bold text-[18px] md:text-[25px] lg:text-[35px] w-[60%] md:w-[50%] lg:w-[50%] xl:w-[40%]">
          Wynajmij przestrzeń magazynową Self-Storage!
        </div>
        <Button
          primary
          onClick={() => {
            window.location.assign('/products');
          }}
          label="Sprawdź magazyny"
        />
        <div className="flex flex-row gap-4 items-center">
          <div className="flex flex-row gap-2 items-center">
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19" cy="19" r="19" fill="#389EFF" />
              <path
                d="M26.9995 23.7278V26.2869C27.0004 26.5245 26.9517 26.7597 26.8563 26.9774C26.7609 27.1951 26.6211 27.3905 26.4457 27.5511C26.2702 27.7117 26.0632 27.834 25.8376 27.9101C25.6121 27.9862 25.3732 28.0145 25.1361 27.9931C22.5059 27.7078 19.9794 26.8109 17.7596 25.3742C15.6943 24.0644 13.9434 22.3169 12.631 20.2558C11.1865 18.0303 10.2875 15.4966 10.0069 12.8598C9.98558 12.6239 10.0137 12.3861 10.0894 12.1616C10.1652 11.9372 10.2869 11.7309 10.4469 11.556C10.6069 11.381 10.8017 11.2413 11.0188 11.1455C11.2359 11.0498 11.4706 11.0003 11.7079 11.0001H14.2722C14.687 10.996 15.0891 11.1426 15.4037 11.4126C15.7182 11.6825 15.9236 12.0574 15.9817 12.4673C16.0899 13.2863 16.2906 14.0905 16.58 14.8645C16.695 15.1698 16.7199 15.5016 16.6517 15.8206C16.5836 16.1396 16.4252 16.4325 16.1954 16.6644L15.1098 17.7478C16.3266 19.8835 18.0984 21.6518 20.2384 22.8662L21.3239 21.7828C21.5563 21.5534 21.8497 21.3954 22.1694 21.3273C22.489 21.2593 22.8215 21.2841 23.1274 21.3989C23.9029 21.6877 24.7087 21.888 25.5293 21.996C25.9445 22.0545 26.3237 22.2632 26.5948 22.5825C26.8658 22.9018 27.0099 23.3094 26.9995 23.7278Z"
                stroke="white"
                strokeWidth="1.71961"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="text-secondary text-[12px] md:text-[16px] font-bold">
              +48 500 780 880
            </div>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19" cy="19" r="19" fill="#389EFF" />
              <path
                d="M11.8 12H26.2C27.19 12 28 12.8104 28 13.8008V24.6056C28 25.5961 27.19 26.4065 26.2 26.4065H11.8C10.81 26.4065 10 25.5961 10 24.6056V13.8008C10 12.8104 10.81 12 11.8 12Z"
                stroke="white"
                strokeWidth="1.71961"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28 13.8008L19 20.1036L10 13.8008"
                stroke="white"
                strokeWidth="1.71961"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div className="text-secondary font-bold text-[12px] md:text-[16px]">
              biuro@4box.pl
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
