import { defaultStyles } from '@/utils/defaultStyles';
import Carousel from 'react-multi-carousel';
import Button from './Button';
import { CustomLeftArrow, CustomRightArrow } from './CustomCarouselArrows';
import { warehouses } from '@/constants';

const dots = [
  {
    title: 'Dostęp 7 dni w tygodniu 24/7',
    desc: 'Dopasowane do Twoich potrzeb, na krótki i długi czas.',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_81_97)">
          <mask
            id="mask0_81_97"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="38"
            height="38"
          >
            <path d="M0 3.8147e-06H38V38H0V3.8147e-06Z" fill="white" />
          </mask>
          <g mask="url(#mask0_81_97)">
            <path
              d="M16.7734 16.7734H1.11328V1.11328H16.7734V16.7734Z"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.7734 36.8867H1.11328V21.2266H16.7734V36.8867Z"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.2266 16.7734H36.8867V1.11328H21.2266V16.7734Z"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.2266 36.8867H36.8867V21.2266H21.2266V36.8867Z"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            d="M4.83228 8.86489L7.62535 11.658L13.0545 6.22871"
            stroke="white"
            strokeWidth="2.22656"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.9456 28.9782L27.7386 31.7712L33.1677 26.342"
            stroke="white"
            strokeWidth="2.22656"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_81_97">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Łatwy i szybki dostęp',
    desc: 'Możliwość wynajmu i płatności online',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_81_47)">
          <mask
            id="mask0_81_47"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="38"
            height="38"
          >
            <path d="M0 3.8147e-06H38V38H0V3.8147e-06Z" fill="white" />
          </mask>
          <g mask="url(#mask0_81_47)">
            <path
              d="M1.11328 19.0742H36.8867"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33984 36.8867V19.0742"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M34.6602 36.8867V19.0742"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33984 14.6211H21.3008V1.11328H3.33984V14.6211Z"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            d="M14.5469 14.6211L16.7734 19.0742"
            stroke="white"
            strokeWidth="2.22656"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.86719 19.0742L10.0938 14.6211"
            stroke="white"
            strokeWidth="2.22656"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <mask
            id="mask1_81_47"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="38"
            height="38"
          >
            <path d="M0 3.8147e-06H38V38H0V3.8147e-06Z" fill="white" />
          </mask>
          <g mask="url(#mask1_81_47)">
            <path
              d="M34.6602 27.9805H21.3008V19.0742"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M34.6602 36.8867H21.3008V27.9805"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M31.3203 19.0742C29.4758 19.0742 27.9805 17.5789 27.9805 15.7344V12.3945H34.6602V15.7344C34.6602 17.5789 33.1649 19.0742 31.3203 19.0742Z"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M35.7734 5.64063C35.7734 5.64063 31.3203 7.94141 31.3203 12.3945"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.8672 5.64063C26.8672 5.64063 31.3203 7.94141 31.3203 12.3945"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M31.3203 3.41406V12.3945"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.0938 23.5273C29.0938 24.1422 28.5953 24.6406 27.9805 24.6406C27.3656 24.6406 26.8672 24.1422 26.8672 23.5273C26.8672 22.9125 27.3656 22.4141 27.9805 22.4141C28.5953 22.4141 29.0938 22.9125 29.0938 23.5273Z"
              fill="white"
            />
            <path
              d="M29.0938 32.4336C29.0938 33.0484 28.5953 33.5469 27.9805 33.5469C27.3656 33.5469 26.8672 33.0484 26.8672 32.4336C26.8672 31.8188 27.3656 31.3203 27.9805 31.3203C28.5953 31.3203 29.0938 31.8188 29.0938 32.4336Z"
              fill="white"
            />
            <path
              d="M3.33984 32.4336H21.3008"
              stroke="white"
              strokeWidth="2.22656"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_81_47">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Pełne bezpieczeństwo',
    desc: 'Plac z magazynami jest oświetlony i monitorowany całą dobę',
    svg: (
      <svg
        fill="#ffffff"
        width="38px"
        height="38px"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M31.119 14.845c-0.116-0.314-0.346-0.563-0.639-0.702l-0.008-0.003-1.765-0.823 0.39-0.836c0.074-0.155 0.117-0.336 0.117-0.528 0-0.499-0.292-0.93-0.715-1.13l-0.008-0.003-21.254-9.911c-0.155-0.074-0.337-0.117-0.529-0.117-0.499 0-0.929 0.292-1.129 0.714l-0.003 0.008-4.589 9.842c-0.074 0.155-0.117 0.337-0.117 0.529 0 0.499 0.292 0.929 0.714 1.129l0.008 0.003 7.773 3.624-2.19 5.11h-3.926v-3.75c0-0.69-0.56-1.25-1.25-1.25s-1.25 0.56-1.25 1.25v0 10c0 0.69 0.56 1.25 1.25 1.25s1.25-0.56 1.25-1.25v0-3.75h4.75c0.512-0 0.953-0.309 1.146-0.75l0.003-0.008 2.484-5.795 11.215 5.229c0.154 0.074 0.334 0.117 0.525 0.117 0.001 0 0.002 0 0.003 0h-0c0.498-0 0.928-0.292 1.13-0.713l0.003-0.008 0.39-0.835 1.767 0.824c0.154 0.074 0.334 0.117 0.525 0.117 0.001 0 0.002 0 0.003 0h-0c0.499-0 0.929-0.293 1.13-0.715l0.003-0.008 2.754-5.904c0.074-0.155 0.117-0.336 0.117-0.527 0-0.154-0.028-0.301-0.079-0.437l0.003 0.009zM22.77 20.133l-11.174-5.211c-0.030-0.023-0.063-0.047-0.098-0.069l-0.005-0.003c-0.029-0.013-0.060-0.009-0.090-0.019l-7.62-3.554 3.532-7.576 18.988 8.854zM26.586 19.516l-0.631-0.295 1.697-3.639 0.631 0.295z"></path>
      </svg>
    ),
  },
  {
    title: 'Wygodny dojazd',
    desc: 'Znajdujemy się tylko 1 minutę drogi od obwodnicy trójmiasta',
    svg: (
      <svg
        fill="#ffffff"
        width="38px"
        height="38px"
        viewBox="0 0 15 15"
        xmlns="http://www.w3.org/2000/svg"
        id="car"
      >
        <path d="M13.84,6.852,12.6,5.7,11.5,3.5a1.05,1.05,0,0,0-.9-.5H4.4a1.05,1.05,0,0,0-.9.5L2.4,5.7,1.16,6.852A.5.5,0,0,0,1,7.219V11.5a.5.5,0,0,0,.5.5h2c.2,0,.5-.2.5-.4V11h7v.5c0,.2.2.5.4.5h2.1a.5.5,0,0,0,.5-.5V7.219A.5.5,0,0,0,13.84,6.852ZM4.5,4h6l1,2h-8ZM5,8.6c0,.2-.3.4-.5.4H2.4C2.2,9,2,8.7,2,8.5V7.4c.1-.3.3-.5.6-.4l2,.4c.2,0,.4.3.4.5Zm8-.1c0,.2-.2.5-.4.5H10.5c-.2,0-.5-.2-.5-.4V7.9c0-.2.2-.5.4-.5l2-.4c.3-.1.5.1.6.4Z" />
      </svg>
    ),
  },
];

export function Slide(props: {
  id: number;
  size: string;
  img: string;
  title: string;
  price: string;
  isStandalone?: boolean;
}) {
  return (
    <div
      className={`flex flex-col items-center mx-4 ${props.isStandalone ? '' : 'max-w-[430px]'}`}
    >
      <div className="w-full flex flex-col gap-4 bg-neutral py-4">
        <img
          loading="lazy"
          alt={props.title}
          src={props.img}
          className="max-w-[320px] h-[200px] self-center"
        />
        <div className="text-[18px] md:text-[24px] font-bold text-secondary px-4">
          {props.title}
        </div>
        <div className="flex flex-row justify-between items-center px-4">
          <div className="text-[18px] md:text-[24px] font-extrabold text-secondary">
            {props.size} m<sup>2</sup>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-[18px] md:text-[24px] font-extrabold text-primary">
              {props.price} zł
            </div>
            <div className="text-[14px] md:text-[16px] font-extrabold text-primary">
              / miesięcznie
            </div>
          </div>
        </div>
        <div className="px-4">
          <Button
            label="Wynajmij magazyn"
            onClick={() => window.location.assign('/products/' + props.id)}
            primary
            class="w-full"
          />
        </div>
      </div>
    </div>
  );
}

export default function HeroSliderSection() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1424 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1424, min: 764 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 764, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      className={`flex flex-col gap-8 py-12 lg:py-20 bg-neutral ${defaultStyles.paddingBig}`}
      id="hero-slider"
    >
      <div className="uppercase text-primary font-[600] text-[16px]">
        Nasza oferta
      </div>
      <div className="text-secondary font-bold text-[24px] md:text-[35px]">
        Twoje rzeczy, nasza przestrzeń
      </div>
      <Carousel
        ssr
        responsive={responsive}
        slidesToSlide={1}
        infinite
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        className="pt-20"
      >
        {warehouses.map((item, index) => (
          <Slide
            id={item.id}
            img={item.smallImage}
            size={item.size}
            price={item.price}
            title={item.name}
            key={index}
          />
        ))}
      </Carousel>
      <div className="text-secondary font-bold text-[24px] md:text-[35px]">
        Dlaczego warto wybrać nasze magazyny?
      </div>
      <div
        className={`py-12 flex flex-row justify-center md:justify-between flex-wrap gap-8`}
      >
        {dots.map((item, index) => (
          <div
            className="flex flex-col items-start gap-4 w-[290px]"
            key={index}
          >
            <div className="bg-primary flex flex-col items-center justify-center max-h-[109px] min-h-[109px] max-w-[109px] min-w-[109px] rounded-full">
              {item.svg}
            </div>
            <div className="font-bold text-[18px] md:text-[24px] text-secondary">
              {item.title}
            </div>
            <div className="text-[14px] md:text-[16px] text-secondary">
              {item.desc}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
